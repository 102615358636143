import { Controller } from "@hotwired/stimulus"
import { debounce } from "../helpers/debounce_helpers"

export default class extends Controller {
  static classes = ["active"]

  initialize() {
    this.scroll = debounce(this.scroll.bind(this), 15)
  }

  scroll() {
    if (window.scrollY >= 40) {
      this.element.classList.add(this.activeClass)
    } else {
      this.element.classList.remove(this.activeClass)
    }
  }
}
