import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    eventName: String
  }

  analyze() {
    window.plausible(this.eventNameValue)
  }
}
